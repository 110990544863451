/**======================================================================
=========================================================================
Template Name: Able Pro - Bootstrap Admin Template
Author: Phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';

@import 'settings/color-variables';
@import 'settings/theme-variables';
@import 'settings/bootstrap-variables';

@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/accordion';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/toasts';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/spinners';
@import 'node_modules/bootstrap/scss/offcanvas';
@import 'node_modules/bootstrap/scss/placeholders';
@import 'node_modules/bootstrap/scss/helpers';

[data-pc-direction='ltr'] {
  @import 'node_modules/bootstrap/scss/utilities';
  @import 'node_modules/bootstrap/scss/utilities/api';
}
[data-pc-direction='rtl'] {
  @import 'themes/layouts/style-rtl-utility';
  @import 'node_modules/bootstrap/scss/utilities/api';
}


// main framework
@import 'themes/generic';
@import 'themes/general';

// Layouts
@import 'themes/layouts/pc-sidebar';
@import 'themes/layouts/pc-header';
@import 'themes/layouts/pc-footer';
@import 'themes/layouts/pc-common';
// @import 'themes/layouts/pc-compact';
// @import 'themes/layouts/pc-horizontal';

// widget
@import 'themes/components/widget';

// Components
@import 'themes/components/avtar';
@import 'themes/components/badge';
@import 'themes/components/button';
@import 'themes/components/dropdown';
@import 'themes/components/card';
@import 'themes/components/progress';
@import 'themes/components/table';
@import 'themes/components/tabs';
@import 'themes/components/form';

// pages
@import 'themes/pages/authentication';
@import 'themes/pages/maintaince';
@import 'themes/pages/pages';
// @import 'themes/pages/help-desk';
@import 'themes/pages/icon-lauouts';
@import 'themes/pages/user';
@import 'themes/pages/application';

// custom pluginss
@import 'themes/plugins/sweatalert';
@import 'themes/plugins/lightbox';
@import 'themes/plugins/notification';
// @import 'themes/plugins/bar-rating';
@import 'themes/plugins/modal';
@import 'themes/plugins/rangeslider';
@import 'themes/plugins/daterange';
@import 'themes/plugins/tags-input';
// @import 'themes/plugins/maxlength';
// @import 'themes/plugins/wizard';
// @import 'themes/plugins/select2';
@import 'themes/plugins/slider';
@import 'themes/plugins/switches';
@import 'themes/plugins/data-tables';
// @import 'themes/plugins/peitychart';

@import 'themes/customizer';
